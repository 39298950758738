import gsap from "gsap"

export default class Menu {
    constructor() {
        this.setTL()
        this.events()
    }

    events() {
        this.openBtn = document.querySelector("#menu-open-btn")
        this.openBtn.addEventListener("click", () => this.show(), false)
        //
        this.closeBtn = document.querySelector("#menu-close-btn")
        this.closeBtn.addEventListener("click", () => this.hide(), false)
    }

    show(e) {
        return this.tl.duration(.35).play()
    }

    hide(e) {
        return this.tl.duration(.35).reverse()
    }

    setTL() {
        this.tl = gsap.timeline({
            paused: true
        }).to("#menu-mobile", {
            display: "flex",
            opacity: 1,
            x: 0
        }, 0)
    }
}