import Rails from "@rails/ujs"
import Layout from "../layout"
import Menu from "../components/menu.js"
// import PhotoEditor from "../photos/editor"

class App extends Layout {
    constructor() {
        super()
        Rails.start()
        this.menu = new Menu()
    }
    
    loaded() {
        this.preloader.hide()
    }

}

window["App"] = new App()
// window["PhotoEditor"] = new PhotoEditor()